@import '../../styles/customMediaQueries.css';

.listingCharactersticsWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.listingCharactersticsHeading {
  font-family: Gilroy;
  font-size: 28px;
  font-weight: 700;
  line-height: 31.14px;
  letter-spacing: 0.01em;
  text-align: left;
  @media (max-width: 720px) {
    margin-left: 24px;
  }
}

.listingCharactersticsContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
  margin-top: 20px;
  padding: 10px;
  @media (max-width: 720px) {
  margin-top: 10px;
  padding: 24px;
  }
}

.listingCharactersticsCard {
  display: flex;
  flex-direction: column;
  min-width: calc(50% - 20px);
  @media (max-width: 720px) {
    min-width: 100%;
  }
}

.listingCharactersticsCardHeading {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 35.04px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--colorBlack);
}

.listingCharactersticsCardItem {
  display: flex;
  align-items: center;
}

.listingCharactersticsCardIcon {
  margin-right: 8px;
  & :global(svg){
    display: flex;
  }
}

.listingCharactersticsCardText {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  line-height: 35.04px;
  letter-spacing: 0.01em;
  margin: 0px;
  padding: 0px;
}

.listingCharactersticsCardValue {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  line-height: 35.04px;
  letter-spacing: 0.01em;
  margin: 0px;
  padding: 0px;
  margin-left: auto;
}
